@mixin font-file($family, $path, $weight, $types) {
  $srcString: local(#{$family});

  @each $type in $types {
    $srcString: #{$srcString}, url('#{$path}.#{$type}') format(#{$type});
  }

  @font-face {
    font-family: $family;
    src: $srcString;
    font-weight: $weight;
    font-display: swap;
  }
}

@include font-file('Intro', '../../fonts/intro/thin-regular', 100, woff woff2);
@include font-file('Intro', '../../fonts/intro/light-regular', 300, woff woff2);
@include font-file('Intro', '../../fonts/intro/book-regular', 400, woff woff2);
@include font-file('Intro', '../../fonts/intro/regular', 500, woff woff2);
@include font-file('Intro', '../../fonts/intro/bold-regular', 700, woff woff2);
