section.search {
  position: relative;
  margin: 96px auto;
  z-index: 99;

  results-container {
    background-color: $white;
    display: grid;
    grid-template-columns: minmax(auto, 100%);
    width: calc(100% + 42px);
    margin-left: -21px;
    height: calc(100% + 1px);
    padding: $gap;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    a {
      padding: $gap;
      transition: $transition;
      border-radius: $border-radius;
      border: 2px solid $grey1;
      margin-bottom: $gap;
      font-size: 20px;
      img {
        width: 80px !important;
        height: unset;
        aspect-ratio: 1;
        margin-right: $gap;
      }

      &:hover {
        background-color: $green;
        color: $white;
        border-color: $green;
      }
    }
  }

  &.sidebar {
    position: relative;

    max-width: unset;
    margin: $gap2 auto;
    background-color: $blue1;

    border-radius: $border-radius;

    padding: $gap2 20px 0;

    h3 {
      color: $green1 !important;
      margin: 0;
      width: 100%;
      display: block;
    }

    filter-bar {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: fit-content;
      align-items: center;
      margin-bottom: $gap2;
      gap: $gap0;
      flex-wrap: wrap;
      > div {
        min-width: 100px;
        margin-bottom: 0;
        flex-grow: 1;
        .select-button {
          border-radius: 40px;
          text-transform: uppercase;
          font-size: 0.8em;
          padding: 7px 12px;
          height: 35px;
        }
      }

      > .button {
        color: white;
        border-color: white;
        font-size: 0.8em;
        padding: 12px 8px 8px;
        height: 35px;
      }
    }

    form {
      background-color: transparent;
      gap: 0 $gap;
      margin: $gap 0;
      position: relative;
      input {
        margin: 0;

        &[type='text'] {
          &:first-of-type {
            width: 100%;
            margin-right: 0;
            max-width: unset;
            border-radius: 40px;
            padding-left: $gap;
          }
        }
      }

      > svg {
        display: none;
      }
      button {
        background-color: $green1;
        min-width: unset;
        width: fit-content;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        position: absolute;
        z-index: 99;
        right: 2px;
        top: 2px;
        border: 1px solid $green1;
        padding: 8px;
        span {
          display: none;
        }
        svg {
          display: block;
          width: 20px;
          height: 20px;
          transition: all $transition;
        }
        &:hover {
          background-color: $white;
          svg {
            fill: $green1;
            cursor: pointer;
          }
        }
      }
    }
  }

  .search-bar {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: space-between;

    border-radius: 8px;

    > svg {
      &:first-of-type {
        position: absolute;
        top: calc(50% - 12px);
        left: 16px;
        height: 24px;
        width: 24px;
        z-index: 999;
      }
    }

    input {
      padding: 0;
      border: none;
      height: 40px;
      z-index: 99;
      box-shadow: none;
      margin: $gap0 auto;

      &[type='text'] {
        min-width: 250px;
        flex-grow: 1;
        max-width: 50%;

        &:first-of-type {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding-left: 48px;
          margin-right: 48px;
        }
      }
    }

    .select-container {
      margin-bottom: 0;
      width: 50%;
      .select-button {
        padding: 0;
        border: none;
        align-items: center;
        display: flex;
        padding: $gap0;
        max-height: 40px;
        &:after {
          content: none;
        }
      }
    }

    button {
      width: auto;
      background-color: white;
      border: none;
      min-width: 150px;

      border-radius: 40px;

      svg {
        display: none;
      }

      span {
        background-color: $green1;
        padding: 10px 8px 8px;
        border-radius: 40px;
        display: block;
        color: white;
        transition: background-color 0.1s ease-in-out;
        font-family: $detail-font-family;
        font-weight: 400;
      }

      &:hover {
        span {
          background-color: $blue1;
          cursor: pointer;
        }
      }
    }

    & + .results {
      width: calc(50% - 16px);
      max-width: 600px;
      background-color: white;
      position: absolute;
      left: 8px;
      top: 104%;
      border-radius: 6px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      display: flex;
      flex-direction: column;
      max-height: 200px;
      overflow-y: auto;

      &:not(:empty) {
        box-shadow: 2px 2px 2px 2px black;
      }

      a {
        padding: 8px;
        text-decoration: none;
        transition: background-color 0.1s ease-in-out;

        & + a {
          border-top: 1px solid $grey1;
        }

        &:hover {
          background-color: $green1;
          color: $white;
        }
      }
    }
  }
  @media (min-width: 768px) {
    max-width: 80%;

    .search-bar {
      flex-direction: row;
    }
  }
}

body.search-page {
  header {
    position: relative;
    overflow: visible;

    section.search {
      margin: $gap2 0 0;
      width: 100%;
    }

    &.has-blowout {
      section.search {
        max-width: unset;
      }
    }

    .search-bar {
      border: 2px solid $blue1;
      margin-bottom: $gap;
      border-radius: 40px;
      padding: $gap0;
      > svg {
        display: none;
      }
      input[type='text'] {
        background: transparent;
        margin: 0;
        width: calc(100% - 70px);
        flex-grow: 0;
        font-size: 20px;
        &:first-of-type {
          border-radius: 40px;
          padding-left: $gap;
          max-width: 100%;
        }
      }

      .select-container {
        width: 50%;
        margin-right: 50px;
      }

      button {
        padding: 0;
        border-radius: 50%;
        background-color: $green1;
        border: 1px solid $green1;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 3px;
        right: 3px;
        min-width: unset;
        span {
          display: none;
        }

        svg {
          display: block;
          height: 25px;
          width: 25px;
          margin: auto;
          transition: $transition;
        }
        &:hover {
          background-color: $white;
          svg {
            fill: $green1;
          }
        }
      }
      @media (max-width: 768px) {
        & + .select-container {
          width: 100%;
          margin-right: 50px;
          padding-left: 0;
          margin-bottom: $gap;

          .select-button {
            padding-left: 40px;
            border-radius: 40px;
            font-size: 14px;
            text-transform: uppercase;
          }
          svg {
            left: 8px;
          }
        }
      }
    }

    filter-bar {
      display: flex;
      flex-direction: column;
      gap: $gap;

      .select-container {
        margin: 0;
        min-width: 100px;
        flex-grow: 1;

        .select-button {
          --arrow-size: 10px;
          border-radius: 40px;
          padding: 4px $gap2 4px $gap;
          font-size: 14px;
          text-transform: uppercase;
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .button {
        padding: #{$gap0 + 4} $gap $gap0 $gap;
        font-size: 12px;
        width: 100%;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .button {
          width: fit-content;
        }
      }
    }
  }

  main {
    .search-results {
      display: grid;
      grid-template-columns: minmax(auto, 100%);
      grid-gap: $gap2;
      margin: 0;

      @media (min-width: 600px) {
        margin: $gap2;
        grid-template-columns: repeat(2, minmax(auto, 50%));
      }

      @media (min-width: 900px) {
        grid-template-columns: repeat(3, minmax(auto, 33%));
      }
    }
  }

  &.magazines {
    background-color: $blue1;
    main {
      color: $white;
      .search-results {
        @media (min-width: 900px) {
          grid-template-columns: repeat(4, minmax(auto, 25%));
        }
      }

      .no-results {
        p {
          color: white;
        }
      }
    }
  }
}
