header {
  width: 100%;
  position: relative;
  padding: $gap;

  &.headings-container {
    .img-title {
      z-index: 9;
      color: white;
    }

    &.has-blowout {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 100%;
      }
      svg {
        display: none;
        margin-right: -100px;
        top: -100px;
      }

      @media (min-width: 768px) {
        > div {
          max-width: 55%;
          margin: 0;
        }
        svg {
          display: block;
        }
      }
    }
  }

  .banner-img {
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
    min-height: calc(100% + 200px);
    position: absolute;
    top: -200px;
    z-index: -1;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    object-fit: contain;

    &.video {
      object-fit: cover;
    }

    img {
      background-color: $green1;
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 200%;
      height: 200%;
      border-radius: 50%;
      left: -50%;
      z-index: 9;
    }
  }

  @media (min-width: 768px) {
    margin: 0;
    padding: 0;

    &.headings-container {
      &.has-blowout {
        height: 400px;
      }
    }
  }
}
