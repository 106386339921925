navigation-bar {
  --bs-nav-link-font-size: 14px;
  --bs-nav-link-font-weight: 400;
  --bs-nav-link-color: #{$blue1};
  --bs-nav-link-hover-color: #{$green1};
  --bs-navbar-active-color: #{$green1};
  --bar-color: #{$blue1};

  padding: 0 $gap0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 150vw;
    height: 150vw;
    border-radius: 50%;
    bottom: -470px;
    left: -50vw;
    margin-left: 20%;
    min-width: 1200px;
    min-height: 1200px;
    transition: opacity $transition;
    animation: fadeIn 0.3s linear;
  }

  &:after {
    background-color: white;
    z-index: -20;
  }

  position: relative;

  .link-title {
    color: var(--bs-nav-link-color);
  }

  .navbar-brand {
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;

    > svg {
      width: 100%;
      height: 100%;
      display: block;
      max-height: 100px;
    }
  }

  .navbar {
    .grunge-line {
      position: absolute;
      height: 6px;
      bottom: 0;
      .st0 {
        fill: $green1;
      }
    }

    .account-btn-container {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      align-items: center;
      gap: $gap0;

      .join-toggle {
        margin-left: 0;
        font-size: 12px;
      }

      .login-toggle {
        align-items: center;
      }
      @media (min-width: 768px) {
        gap: $gap2;
        flex-direction: row;
        .join-toggle {
          font-size: 16px;
        }
      }
    }
  }

  nav.desktop {
    flex-grow: 1;
    gap: 0 $gap2;
    .navigation-bar-links {
      flex: 1;

      .nav-item {
        position: relative;
        &:first-child .nav-link {
          padding-left: 0;
        }

        .nav-link {
          position: relative;
          white-space: nowrap;
          z-index: 9;
          // TODO: bs variables for hover and active on white backgrounds
          span {
            font-family: $detail-font-family;
            text-transform: uppercase;
            font-weight: 500;
          }

          svg.search {
            margin-right: 6px;
          }

          &.active.underline {
            &:before {
              background-size: cover;
              height: 6px;
              bottom: 0px;
            }
          }
        }

        &:not(:last-child) {
          --link-spacing: 1.5rem;

          margin-right: var(--link-spacing);

          .nav-link {
            position: relative;
            height: fit-content;

            &::after {
              --bar-width: 2px;
              --bar-height: calc(50% - (var(--bs-navbar-nav-link-padding-x)));

              content: '';
              display: block;
              position: absolute;
              top: calc(50% - var(--bar-height) / 2);
              left: calc(100% - (var(--bar-width) / 2) + (var(--link-spacing) / 2));
              width: var(--bar-width);
              height: var(--bar-height);
              background-color: var(--bar-color);
            }
          }
        }

        .dropdown {
          position: absolute;
          top: 0px;
          left: -$gap;
          z-index: -1;
          pointer-events: none;
          opacity: 0;
          background-color: white;
          transition: all $transition;
          padding: 36px 4px $gap0;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          max-width: 200px;
          width: 300%;

          &:before {
            content: '';
            width: 100%;
            left: 0;
            top: 0px;
            height: 40px;
            background-color: $primary;
            z-index: 9;
            display: block;
            position: absolute;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
          }

          .nav-item {
            margin-right: unset;
            padding: 4px 0;
            .nav-link {
              padding: 12px $gap;
              transition: background-color $transition;
              &::after {
                content: none;
              }
              .link-title {
                white-space: wrap;
                color: $primary;
              }
            }

            &:not(:last-of-type) {
              border-bottom: 1px solid #c7c7c7;
            }

            &:last-of-type {
              padding-bottom: 0;
            }

            &:hover .nav-link {
              background-color: #f5f1ec;
            }
          }
        }

        &.has-children:hover > .dropdown,
        .dropdown:hover {
          z-index: 1;
          opacity: 1;
          pointer-events: all;
        }

        &.has-children:hover {
          z-index: 9;
          background-color: $primary;
          .link-title {
            color: $green1;
          }
        }
      }
    }

    .navbar-brand {
      & + .navigation-bar-links {
        justify-content: flex-end;

        .nav-item:last-child .nav-link {
          padding-right: 0;
        }
      }
    }
  }

  nav.mobile {
    position: relative;
    justify-content: space-between;
    gap: 16px;

    .mobile-nav-controls {
      --icon-size: 35px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      order: 3;

      > * {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        width: var(--icon-size);
        height: var(--icon-size);
        padding: 0;

        svg {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          max-width: unset;
          fill: $green1;
        }
      }
    }

    .mobile-nav {
      --link-spacing: #{10px};

      counter-reset: mobile-link;

      position: absolute;
      top: calc(100% - var(--bs-navbar-padding-y));
      right: 0;
      display: flex;
      flex-direction: column;
      gap: var(--link-spacing);
      width: min(100%, 300px);
      background-color: rgba($blue1, 0.95);
      border-radius: $border-radius;
      padding: var(--link-spacing) calc(var(--link-spacing) * 2);
      box-shadow: 0px 0px 20px 1px rgba($blue1, 0.3);
      backdrop-filter: blur(2px);

      .nav-link {
        counter-increment: mobile-link;
        border-radius: $border-radius;
        color: $white;

        span {
          display: flex;
          justify-content: space-between;
          font-family: $detail-font-family;
          font-weight: 400;
          text-transform: uppercase;
          color: $white;
          text-align: right;
        }

        &:hover {
          color: $green1;

          span::before {
            opacity: 1;
          }
        }

        span::before {
          content: '0' counter(mobile-link);
          display: inline-block;
          position: relative;
          font-family: $detail-font-family;
          font-weight: 300;
          color: $white;
          opacity: 0.5;
          transition: $transition-base;
          white-space: nowrap;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    display: flex;
    padding: 0;
  }
}

// Overwrite link colours for homepage
body.home {
  navigation-bar {
    .navbar {
      .nav-item {
        --bar-color: white !important;
        --bs-nav-link-color: white;
        --bs-nav-link-hover-color: #{$green1};
        --bs-navbar-active-color: #{$blue1};
        --bs-nav-link-font-size: 14px;
      }

      .join-toggle,
      .login-toggle p {
        color: white;
        &:hover {
          color: $green1;
        }
      }
    }

    &:before {
      content: '';
      top: -24px;
      left: -50vw;
      position: absolute;
      display: block;
      background-image: linear-gradient(to bottom, $black, transparent);
      width: 100vw;
      height: 100%;
      z-index: 0;
      margin-left: 50%;
      min-height: unset;
      min-width: unset;
      bottom: unset;
      border-radius: unset;
      opacity: 1;
    }

    &:after {
      content: none;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
