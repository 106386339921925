@use '../_modules' as *;

.loading-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vw;

  .progress {
    display: flex;
    margin: 50vh 0 0;
    --bs-progress-bar-bg: #{$green1};
    width: 100%;
  }

  .loading-screen-container {
    display: flex;
    justify-content: space-around;

    img,
    svg {
      width: 300px;
      height: 300px;
      position: absolute;
      z-index: 999;
      top: calc(50% - 150px);
      left: calc(50% - 150px);
    }

    loading-bar {
      height: 350px;
      width: 350px;
      position: relative;
      bar-left,
      bar-right {
        position: absolute;
        height: 100%;
        width: 100%;
        background: #fff;
        border-radius: 100%;
        clip: rect(0px, 350px, 350px, 175px);
        z-index: 3;
      }

      bar-left {
        progress-bar {
          animation: left 4s linear both;
        }
      }

      bar-right {
        transform: rotate(180deg);
      }

      progress-bar {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        clip: rect(0px, 175px, 350px, 0px);
        background: $green3;
        z-index: 999;
      }
      &.loading {
        bar-left {
          progress-bar {
            animation-duration: 0.5s;
          }
        }

        bar-right {
          transform: rotate(180deg);

          progress-bar {
            animation: right 1.5s linear both;
            animation-delay: 0.5s;
          }
        }
      }
    }
  }
}
