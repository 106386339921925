html {
  body {
    overflow-x: hidden;
    background-color: $beige;
    transition: background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .site-alert {
    text-align: center;
    padding: 4px 0;
    background-color: #5bb13a;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    z-index: 9999;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;

    & + navigation-bar {
      margin-top: 29px;
    }
  }

  #body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    grid-template-rows: auto;
    min-height: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: hidden;

    main {
      z-index: 1;
      max-width: 100%;
      width: 100%;
      margin: $spacer2 auto;
      padding: $spacer0;

      @media (max-width: 768px) {
        margin: 0 auto;
      }

      &.overflow-none {
        overflow: unset;
      }

      .progress {
        grid-column: 1 / -1;
        margin: 180px auto 75vh;
        max-width: 66%;
        width: 100%;
        --bs-progress-bar-bg: #{$green1};
      }

      section {
        position: relative;
        &:not(:first-of-type) {
          margin: $gutter3 0;
        }
      }
    }

    @media (min-width: 768px) {
      padding: 0 $gap;
      main {
        padding: $spacer2 $spacer;
      }
    }

    @media (min-width: 1160px) {
      max-width: 1366px;
      overflow-x: visible;
      main {
        padding: 0;
      }
    }
  }
}

img {
  position: relative;
  width: 100%;
  height: 100%;
}
