input,
select {
  padding: 0 $gap2;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: $base-font-family;
  max-height: 50px;
}

input {
  margin-bottom: $gap;
  &[type='email'],
  &[type='password'],
  &[type='text'] {
    padding: $gap $gap2;
    border-radius: 24px;
    border: none;
  }

  &[type='checkbox'] {
    padding: unset;
  }
}

select {
  padding: $gap $gap2;
  border-radius: 24px;
  border: none;
  margin-bottom: $gap;
}

label {
  margin-bottom: $gap;
}

textarea {
  padding: $gap2;
  border-radius: 24px;
  border: none;
  min-height: 250px;
  margin-bottom: $gap2;
  box-shadow: $shadow;
}

.select-container {
  position: relative;
  align-items: center;
  display: flex;
  margin-bottom: $gap2;
  font-family: $base-font-family;

  &:has(svg) {
    padding-left: 30px;
  }

  svg {
    height: 24px;
    width: 24px;
    position: absolute;
    top: calc(50% - 12px);
    left: 0px;
  }

  &.disabled {
    pointer-events: none;
    .select-button {
      opacity: 0.3;
    }
  }
  > .select-button {
    padding: 0.3rem 0.5rem;
    min-width: 100%;
    font-weight: 500;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid $blue1;
    cursor: pointer;
    transition: $transition;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    height: 100%;
    text-transform: capitalize;
    &:before {
      position: absolute;
      height: var(--arrow-size, 12px);
      width: var(--arrow-size, 12px);
      border-right: 2px solid $blue1;
      border-bottom: 2px solid $blue1;
      content: '';
      top: calc(50% - (var(--arrow-size, 12px) * 0.7));
      right: $gap;
      transform: rotate(45deg);
      z-index: 3;
      transition: $transition;
    }

    &:after {
      position: absolute;
      display: block;
      height: calc(100% - 2px);
      width: $gap2;
      right: 1px;
      top: 1px;
      content: '';
      background-color: white;
      z-index: 2;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      transition: $transition;
    }
  }

  .dropdown-styled {
    position: absolute;
    top: 103%;
    width: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #fff;
    transition: max-height 0.3s ease;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: 2px 2px 2px 2px black;
    max-height: 0;
    &.open {
      max-height: 20vmax;
      visibility: visible;
      z-index: 999;
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 8px;
      &:hover,
      :focus,
      :focus:hover {
        background-color: $green1;
        color: #fafafa;
        outline: none;
        cursor: pointer;
      }

      & + .dropdown-item {
        border-top: 1px solid #dddddd;
      }
    }
  }
}
