@use 'sass:math';

// Colours ---------------------------------------------------------------------

$green: #1abf67; // primary brand
$green2: #e3f7ed; // wavey lines
$blue: #212a4d; // secondary brand / text
$beige: #f4f1ed; // background
$grey: #f3f3f3; // borders / tags
$grey3: #f6f7fa; // background
$grey3: #a4a9ad; // cross symbol
$black: #262626; // logo copy & outline
$blue2: #009bd5; // logo eye
$yellow: #fcbd00; // logo break & claw
$black: #000100;

$green1: #1abf67;
$green2: #33df83;
$green3: #45e05a;
$green4: #62e86c;
$green5: #6ff778;

$blue1: #212a4d;

$grey1: #dddddd;
$grey2: #cccccc;
$grey3: #f5f4f8;

$white: #ffffff;

$primary: #5bb13a; // logo circle
$secondary: $blue;

$brand-gradient: linear-gradient(to right, #1abf67 8%, #33df83 52%, #45e05a 75%, #62e86c 97%);

$box-shadow: 0 0 0.8em rgba(black, 0.2);

// Social Colour ---------------------------------------------------------------

$facebook: #4267b2;
$twitter: #1da1f2;
$youtube: #ff0000;
$instagram: #c32aa3;

// Animations ------------------------------------------------------------------

$ease-in: cubic-bezier(0.42, 0, 1, 1);
$ease-out: cubic-bezier(0, 0, 0.58, 1);
$ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

$transition: 0.2s $ease-in-out;

// Units -----------------------------------------------------------------------

@function gap($amount) {
  @return $gap * $amount;
}

@function gutter($amount) {
  @return calc($gap * $amount);
}

@function spacer($amount) {
  @return calc(($gap * $amount) * 2);
}

$gap: 16px;
$gap-: gap(-1);
$gap0: gap(0.5);
$gap0-: gap(-0.5);
$gap2: gap(2);
$gap2-: gap(-2);
$gap3: gap(3);
$gap3-: gap(-3);
$gap4: gap(4);
$gap4-: gap(-4);
$gap5: gap(5);
$gap5-: gap(-5);
$gap6: gap(6);
$gap6-: gap(-6);

$gutter0: gutter(0.5);
$gutter0-: gutter(-0.5);
$gutter: gutter(1);
$gutter-: gutter(-1);
$gutter2: gutter(2);
$gutter2-: gutter(-2);
$gutter3: gutter(3);
$gutter3-: gutter(-3);
$gutter4: gutter(4);
$gutter4-: gutter(-4);

$spacer0: spacer(0.5);
$spacer0-: spacer(-0.5);
$spacer: spacer(1);
$spacer-: spacer(-1);
$spacer2: spacer(2);
$spacer2-: spacer(-2);
$spacer3: spacer(3);
$spacer3-: spacer(-3);
$spacer4: spacer(4);
$spacer4-: spacer(-4);

// Replace spacers units to gutters units and visa versa.
$spacer-to-gutter: calc(#{$spacer-} + #{$gutter});
$gutter-to-spacer: calc(#{$gutter-} + #{$spacer});
$spacer0-to-gutter0: calc(#{$spacer0-} + #{$gutter0});
$gutter0-to-spacer0: calc(#{$gutter0-} + #{$spacer0});

$border-radius: 1em;
$border-radius-small: 0.5em;

// Breakpoints -----------------------------------------------------------------

$breakpoint-min: 320px;
$breakpoint-small: 480px;
$breakpoint-small-medium: 600px;
$breakpoint-medium: 960px;
$breakpoint-large: 1200px;
$breakpoint-max: 1800 px;
$breakpoint-full: $breakpoint-max + $gap4;

// Layout ----------------------------------------------------------------------

$layout-gutter: max($gutter, calc((100% - #{$breakpoint-max}) / 2));
$default-grid-columns: repeat(6, 1fr);
$base-grid-columns: calc($layout-gutter - $gutter) $default-grid-columns calc($layout-gutter - $gutter);

// An index of named z-index values to be used on a component level
$z-index-navigation: 10;
$z-index-modal: 20;

// Typography ------------------------------------------------------------------

@function font($size, $typeface: $detail-font-family, $line-height: 1.1, $weight: 400, $style: normal) {
  @return $style $weight $size #{'/'} $line-height $typeface, 'Helvetica', sans-serif;
}

$typeface: 'Helvetica Neue';
$font-size: 1.8rem;
$line-height: 1.6;

$base-font-family: 'English Grotesque', sans-serif;
$headings-font-family: 'Droid Serif', serif;
$detail-font-family: 'Intro', sans-serif;

$grot-font: $base-font-family;
$droid-font: $headings-font-family;
$intro-font: $detail-font-family;

$typefaces: (
  (
    // Logo Text
    name: 'Mylius',
    styles: [regular],
    weights: [bold]
  ),
  (
    // Logo est' text
    name: 'Adobe Caslon Pro',
    styles: [italic],
    weights: [regular]
  ),
  (
    // Headings
    name: 'Droid Serif',
    styles: [regular],
    weights: [bold]
  ),
  (
    // Sub headings
    name: 'Intro',
    styles: [regular],
    weights: [bold]
  ),
  (
    // Text
    name: 'Basis Grotesque Pro',
    styles: [regular],
    weights: [regular]
  )
);

$border: 1px solid $blue1;

// Responsive font size will reduce by 10% on smaller screens
$responsive-font-size: clamp(($font-size - ($font-size / 100 * 10)), 2.5vw, $font-size);
$small-font-size: 0.9em;
$medium-font-size: 1.1em;
$large-font-size: 1.7em;

$light-font: 400;
$medium-font: 700;
$regular-font: 700;
$bold-font: 900;

$default-font: font(
  $size: $responsive-font-size,
  $line-height: $line-height,
);
$h1-font: font(
  $size: clamp(2.8em, 4.5vw, 4em),
);
$h2-font: font(
  $size: 2.2em,
  $weight: $bold-font,
);
$h3-font: font(
  $size: 2em,
  $weight: $bold-font,
);
$h4-font: font(
  $size: 1.7em,
  $weight: $bold-font,
);
$h5-font: font(
  $size: 1.3em,
  $weight: $bold-font,
);
$h6-font: font(
  $size: 1em,
  $weight: $bold-font,
);

$button-gap: 6px;
$button-gap-0: calc(#{$button-gap} / 2);
$button-gap-2: calc(#{$button-gap} * 2);
$button-gap-3: calc(#{$button-gap} * 3);
$button-gap-4: calc(#{$button-gap} * 4);
$button-gap-5: calc(#{$button-gap} * 5);
$button-gap-6: calc(#{$button-gap} * 6);

$shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.1);
