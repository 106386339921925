svg.blowout {
  $imageSize: var(--imageSize, 500);
  $blowout: var(--imageBlowout, 50);
  color: white;
  border-radius: 50%;
  width: calc($imageSize * 1px);
  height: calc($imageSize * 1px);
  overflow: visible;
  background: var(--background-colour, linear-gradient(to right, #4de25b, #20c76f));
  image {
    height: calc(($imageSize + $blowout) * 1px);
    width: calc(($imageSize + $blowout) * 1px);
    display: block;
  }
}
