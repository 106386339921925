html,
body {
  min-height: 100%;
  height: 100%;
  margin: 0;
}

a {
  cursor: pointer;
  word-break: break-word;
  text-underline-offset: 3px;
}
