footer {
  margin-top: auto;
  padding: $gap4 $gap2;
  border-top: 2px solid $blue1;
  position: relative;
  z-index: 999;

  &:before {
    background-color: $white;
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: -50vw;
    margin-left: 50%;
    z-index: -1;
  }

  a {
    transition: $transition;

    &.underline {
      &:before {
        content: none;
      }
    }

    &:hover {
      color: $green1;
    }
  }

  .nav-item {
    .nav-link {
      .link-title {
        text-decoration: underline;
      }
    }
  }

  > div {
    display: grid;
    grid-template-columns: minmax(auto, 100%);
    grid-gap: $gap4;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      .logo {
        display: flex;
        width: 240px;
        margin: 0 auto 0 0;
      }

      .button {
        padding-left: $gap2;
        padding-right: $gap2;
        width: 100%;
        margin: 0 auto;
      }

      &:first-of-type {
        width: fit-content;
      }
    }
    &:last-of-type {
      margin-top: $gap2;
      padding-top: $gap2;
      grid-gap: $gap;
      position: relative;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      // &:before {
      //   content: '';
      //   background-image: url('/images/grunge-line.svg');
      //   background-repeat: repeat;
      //   display: block;
      //   height: 4px;
      //   width: 100%;
      //   left: 0;
      //   top: 0px;
      //   position: absolute;
      //   background-position: center;
      //   background-size: cover;
      //   z-index: -1;
      //   fill: $blue1;
      // }

      p {
        text-align: center;
      }
    }
  }

  @media (min-width: 768px) {
    padding: $gap4 0;
    border-top: none;
    > div {
      grid-template-columns: minmax(auto, 20%) minmax(auto, 60%) minmax(auto, 20%);
      &:last-of-type {
        grid-template-columns: 100%;
        > p {
          grid-column: 1;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: flex-start;
        align-items: center;

        &:nth-of-type(2) {
          grid-column: 2;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: $gap0 0;

          > div {
            flex: 0 0 calc(50% - #{$gap});

            a {
              display: block;
              position: relative;
              max-width: 100%;
            }
          }
        }
        &:last-of-type {
          text-align: center;
        }
      }
    }
  }
}
