.button {
  border: $border;
  display: block;
  padding: 16px $button-gap-6 $button-gap-2 $button-gap-6;
  text-decoration: none;
  width: fit-content;
  border-radius: 3em;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.8px;
  transition: $transition;
  color: var(--btn-text-color);
  background-color: var(--btn-background-color);
  border-color: var(--btn-border-color);
  font-family: $detail-font-family;
  text-decoration: none;
  font-weight: 400;
  &:hover {
    background-color: var(--btn-background-color-hover);
    color: var(--btn-text-color-hover);
  }

  --btn-background-color-hover: #{$white};

  &.blue1 {
    --btn-background-color: #{$blue1};
    --btn-border-color: #{$blue1};
    --btn-text-color: #{$white};
    --btn-text-color-hover: #{$blue1};
  }

  &.green1 {
    --btn-background-color: #{$green1};
    --btn-border-color: #{$green1};
    --btn-text-color: #{$white};
    --btn-text-color-hover: #{$green1};
  }
  &.green2 {
    --btn-background-color: #{$green2};
    --btn-border-color: #{$green2};
    --btn-text-color: #{$white};
    --btn-text-color-hover: #{$green2};
  }
  &.green3 {
    --btn-background-color: #{$green3};
    --btn-border-color: #{$green3};
    --btn-text-color: #{$white};
    --btn-text-color-hover: #{$green3};
  }
  &.green4 {
    --btn-background-color: #{$green4};
    --btn-border-color: #{$green4};
    --btn-text-color: #{$white};
    --btn-text-color-hover: #{$green4};
  }

  &.white {
    --btn-background-color: #{$white};
    --btn-border-color: #{$white};
    --btn-text-color: #{$blue1};
    --btn-text-color-hover: #{$white};
    --btn-background-color-hover: #{$blue1};
  }

  &.inverted {
    --btn-background-color: transparent;
    &.blue1 {
      --btn-border-color: #{$blue1};
      --btn-text-color: #{$blue1};
      --btn-background-color-hover: #{$blue1};
      --btn-text-color-hover: #{$white};
    }

    &.green1 {
      --btn-text-color: #{$green1};
      --btn-border-color: #{$green1};
      --btn-background-color-hover: #{$green1};
      --btn-text-color-hover: #{$white};
    }

    &.green2 {
      --btn-text-color: #{$green2};
      --btn-border-color: #{$green2};
      --btn-background-color-hover: #{$green2};
      --btn-text-color-hover: #{$white};
    }

    &.green3 {
      --btn-text-color: #{$green3};
      --btn-border-color: #{$green3};
      --btn-background-color-hover: #{$green3};
      --btn-text-color-hover: #{$white};
    }

    &.green4 {
      --btn-text-color: #{$green4};
      --btn-border-color: #{$green4};
      --btn-background-color-hover: #{$green4};
      --btn-text-color-hover: #{$white};
    }
    &.white {
      --btn-background-color: #{transparent};
      --btn-border-color: #{$white};
      --btn-text-color: #{$white};
      --btn-text-color-hover: #{$blue1};
      --btn-background-color-hover: #{$white};
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
