body.home {
  --nav-bar-color: #{$white};
  background-color: $white;
  header {
    padding: $spacer4 $gap;
    h1 {
      text-shadow: 2px 2px black;

      span {
        text-shadow: none;
      }
      @media (min-width: 768px) {
        max-width: 50%;
      }
      @media (min-width: 1100px) {
        max-width: 33%;
      }
    }
  }

  @media (max-width: 768px) {
    section.search {
      display: none;
    }
  }

  nav.mobile {
    position: relative;
    justify-content: space-between;

    .navbar-brand {
      filter: brightness(0) invert(1);
    }
  }

  h1 {
    color: white;
  }

  main {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    #body main {
      margin-top: 0;
      section {
        margin: $spacer 0;

        &.species-index {
          h2 {
            margin-bottom: $gap;
          }
          .species-container {
            margin: $gap2 0;
          }
        }

        &.become-a-member {
          margin-top: $spacer2;
        }

        &.signup {
          .blowout {
            display: none;
          }
        }
      }
    }
  }

  .home-intro {
    margin: $spacer2 0;
    display: grid;
    grid-template-columns: minmax(auto, 100%);
    position: relative;

    &::before {
      position: absolute;
      top: -48px;
      height: calc(100% + 48px);
      width: 100vw;
      content: '';
      border-radius: $border-radius;
      background-image: url('/images/background-lines.svg');
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
      left: -50vw;
      margin-left: 50%;
      opacity: 0.1;
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(auto, 50%));
      padding: $gutter4 0;
    }
  }

  .home-nav {
    display: grid;
    grid-gap: $gap;
    grid-template-columns: minmax(auto, 100%);

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(auto, 50%));
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(6, minmax(auto, 1fr));

      div {
        grid-column: span 2;
        background-color: $blue1;
        border-radius: $border-radius;

        &:nth-of-type(1) {
          grid-column: 1 / 4;
          background-color: $green1;
        }
        &:nth-of-type(2) {
          grid-column: 4 / -1;
          background-color: $green1;
        }
      }
    }
  }
}
