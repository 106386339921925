@use '../_modules' as *;

body {
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    left: -50vw;
    margin-left: 50%;
    background-color: black;
    z-index: -1;
    transition: $transition;
    opacity: 0;
  }

  &.modal-open {
    overflow: hidden;

    &:before {
      opacity: 0.7;
      z-index: 2;
    }
  }
}

@media (min-width: 1200px) {
  .account-btn-container {
    position: absolute;
    top: 32px;
    right: 0;

    .join-toggle,
    .login-toggle {
      margin-left: 0;
    }
  }
}

.account-btn-container {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.join-toggle,
.login-toggle {
  padding: 0;
  background: none;
  border: none;
  transition: $transition;
  order: 2;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  p {
    color: $blue1;
    text-transform: uppercase;
    font-family: $grot-font;
    margin-bottom: 0;
  }
  svg {
    height: 32px;
    width: 32px;
    fill: $blue1;
    transition: $transition;
    margin-left: $gap0;
  }
  &:hover {
    color: $green1;
    svg {
      fill: $green1;
    }
  }

  @media (max-width: 768px) {
    p {
      font-size: 12px;
    }
    svg {
      margin-left: 0;
    }
  }
}

body navigation-bar .modal {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: $white;
  top: 10vh;
  z-index: -1;
  opacity: 0;
  transition: $transition;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;

  &.logged-in {
    > .container {
      flex-direction: column;
      & > form {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: $gap;
        & > div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          > input {
            width: 100%;
            margin: 0;
          }
          > label {
            font-size: 12px;
            margin: 0 0 0 32px;
            z-index: 9;
          }
        }

        & + div {
          display: flex;
          flex-direction: column;
          gap: $gap;
          button {
            width: 100%;
          }
          @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
  }

  > .details {
    flex-direction: column;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $gap2;
      width: 100%;
      button {
        width: auto;
      }

      @media (min-width: 768px) {
        flex-direction: row;
      }

      &.subscription-details {
        display: flex;
        flex-direction: column;
        h2 {
          font-family: Intro, sans-serif;
          font-size: 32px;
          margin: 0 auto;
        }

        h2,
        & > span {
          color: white;
        }

        a.subscribe {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  > .container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    gap: $gap;
    justify-content: space-between;
  }

  > button {
    position: absolute;
    right: 0;
    color: $green1;
    span {
      display: none;
    }
    svg {
      height: 48px;
      width: 48px;
    }
    &:hover {
      color: $blue1;
    }
  }

  > div {
    flex: 0 0 100%;
    padding: $spacer;
    max-width: 100%;

    &:last-of-type {
      background-color: $blue1;
      align-items: center;
    }

    p {
      text-align: center;

      .underline:before {
        bottom: -10px;
      }
    }

    form {
      position: relative;

      &.forgotten-pwd {
        display: flex;
        flex-direction: column;
        gap: $gap;
        margin: 0 auto;
      }

      &.login {
        width: 66%;
        margin: $gap6 auto;
      }

      h2 {
        color: $blue1 !important;
        margin: 0 auto;
        font-family: $intro-font;
        font-size: 32px;
      }

      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 200%;
        height: calc(100% + #{$gap6});
        top: -$gap3;
        right: -200px;
        margin-left: 20%;
        background-image: url('/images/background-lines.svg');
        background-size: 200%;
        opacity: 0.1;
        z-index: -10;
      }
      input {
        border: 1px solid $blue1;
      }

      .button {
        width: 100%;
      }

      .remember-me,
      .forgot-password {
        width: 50%;
        display: inline-block;
        margin: 0 0 $gap;
      }

      .forgot-password {
        text-align: right;
      }

      .hidden {
        display: none;
      }
    }
  }
  @media (min-width: 768px) {
    top: 15vh;
    flex-direction: row;
    > div {
      flex: 0 0 50%;
      padding: $spacer2;
      max-width: 50%;

      form {
        max-width: 66%;
      }
      &:last-of-type {
        display: flex;
      }
    }
    > button {
      color: white;
      &:hover {
        color: $green1;
      }
    }
  }

  .logoSolo {
    height: 60px;
    width: 60px;
    position: absolute;
    top: $gap;
  }

  &.open {
    display: flex;
    z-index: 9999;
    opacity: 1;
    transition-delay: 0.3s;
  }
}

// Overwrite link colours for homepage
body.home {
  navigation-bar .navbar {
    .join-toggle,
    .login-toggle {
      svg {
        fill: white;
      }

      &:hover {
        svg {
          fill: $green1;
        }
      }
    }
  }
}
