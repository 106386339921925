a,
p,
li,
.button,
.select-container,
span,
input,
textarea,
label,
strong {
  font-family: $base-font-family;
  color: $blue1;
  position: relative;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $headings-font-family;
  width: fit-content;
  color: #1abf67;
  font-weight: 600;
  span {
    font-family: $headings-font-family;
  }
}

h1 {
  font-size: 48px;
  color: $green1;
  span {
    font-family: $headings-font-family;
    font-size: 48px;
  }

  @media (min-width: 768px) {
    font-size: 56px;
    span {
      font-size: 56px;
    }
  }
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 28px;
  font-family: $intro-font;
}

h4 {
  font-size: 24px;
  font-family: $intro-font;
}

h5 {
  font-size: 20px;
  font-family: $intro-font;
  span {
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    strong {
      font-family: 'Intro Bold';
    }
  }
}

a {
  text-decoration: none;
}

.light {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #1abf67;
  }
}

.subtitle {
  font-size: 20px;
  display: block;
  text-transform: uppercase;
  font-family: $intro-font;
  font-weight: 500;
  margin-bottom: $gap0;
}

.strong {
  font-weight: 600;
}

.green {
  color: $green1;
}

.white {
  color: $white;
}

.blue {
  color: $blue1;
}

.block {
  display: block;
  margin-left: $gap6;
}

.hollow {
  font-family: $headings-font-family;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
  color: transparent !important;
  position: relative;
  width: fit-content;
  display: inline-block;

  &.green {
    -webkit-text-stroke-color: $green1;
  }

  &.blue {
    -webkit-text-stroke-color: $blue1;
  }
}

.underline {
  &:before {
    content: '';
    background-image: url('/images/grunge-line.svg');
    background-repeat: no-repeat;
    display: block;
    height: 8px;
    width: 100%;
    left: 0;
    bottom: -4px;
    position: absolute;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }

  &.white {
    color: $white;
  }
}
